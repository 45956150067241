import PropTypes from "prop-types"
import React from "react"

const Hero = ({ children }) => (
	<div id='hero'>
		<div>{children}</div>
	</div> 
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
