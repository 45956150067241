import React from "react"
import { Link } from 'gatsby'

import Hero from "../components/hero.js" 
import Layout from "../components/layout.js"
import MaxWidth from "../components/maxWidth"
import SEO from "../components/seo.js"

const ContactUs = () => (
    <>
        <Hero>
            <h1>404 - Page Not Found</h1>
        </Hero>
     <Layout>
     <SEO title="404 | Corporate EAP Resources" keywords={[`EAP Contact`, `Employee Assistance`, `Employee Help`]} description="Oops, you found our 404 page by mistake." />
     <div>
      <div>
        <MaxWidth>
          <h2>Sorry, that page does not exist on our site</h2>
          <p>Check the URL or use one of the links here to find your way back to our site.</p>
          <p><Link to='/'>Home</Link><br/>
          Home page of EAP.help.</p>
          <p><Link to='/for-employees'>Information for Employees</Link><br/>
          Information beneficial for Employees</p>
          <p><Link to='/for-employers'>Information for Employers</Link><br/>
          Information beneficial for Employers</p>
          <p><Link to='/about-referrals'>Referrals</Link><br/>
          How self or management referrals work</p>
          <p><Link to='/about-referrals'>Privacy</Link><br/>
          Our privacy information page</p>
          <p><Link to='/about-referrals'>Contact Us</Link><br/>
          Ways to contact Corporate EAP Resources</p>
        </MaxWidth>
      </div>
     </div>
     
        
    </Layout>
	 </>
)

 
export default ContactUs